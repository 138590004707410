<template>
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <h3 class="py-5">
          Thank you for choosing Exxtra
        </h3>
      </div>
      <div class="text-center my-2">
        <Check />
      </div>
      <div class="text-center">
        <b-link :to="{ name: 'nss-exxtra'}">
          <b-button variant="primary" block>
            Learn More
          </b-button>
        </b-link>
      </div>
    </template>
  </AuthWrapper>
</template>

<script>
import Check from "@/assets/svg/Check.svg";
import { BButton, BLink } from "bootstrap-vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

export default {
  name: "Success",
  components: {
    Check,
    AuthWrapper,
    BButton,
    BLink
  }
}
</script>

<style scoped>

</style>
